<template>
  <div class="inside-bg">
    <div class="container" style="padding-top: 100px">
      <nav area-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/training-courses">Training courses</a></li>
          <li class="breadcrumb-item active" aria-current="page" v-if="course">
            {{ course.course_name }}
          </li>
        </ol>
      </nav>
      <div class="col">
        <div class="row">
          <div class="col-lg-12 jobDetails">
            <div class="jobHeader" v-if="loading" style="height:200px;">
              <content-loader viewBox="0 0 476 50" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="loading">
                <rect x="0" y="8" rx="3" ry="3" width="150" height="10" />
                <rect x="0" y="25" rx="3" ry="3" width="80" height="6" />
                <rect x="90" y="25" rx="3" ry="3" width="80" height="6" />
                <rect x="0" y="40" rx="3" ry="3" width="100" height="6" />
                <rect x="0" y="55" rx="3" ry="3" width="80" height="6" />
              </content-loader>
            </div>
            <div class="jobHeader" v-if="course && !loading">
              <h1 v-if="course.course_name">{{ course.course_name }}</h1>
              <h2 v-if="course.institute_name">
                <span class="ml-0 jobIcon">
                  <img :src="$S3_url+'/Assets/images/company.svg'" alt="company name" title="company name" width="18"/>
                </span>
                {{ course.institute_name }} &nbsp;&nbsp;
              </h2>
              <h2 v-if="course.institute_name">
                <span>Category: </span>{{ course.category_name }}
              </h2>
              <div class="float-right">
                <button class="btn btn-primary"  @click="showInterest($event)" v-if="!course.interested">Show interest</button>
                <div v-else><v-icon class="text-white mr-2" size="14px">{{ $icons.check }}</v-icon>Interested</div>
              </div>

            </div>
            <div class="jobDescription" v-if="loading">
              <div style="height:110px;">
                <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="loading">
                  <rect x="0" y="5" rx="3" ry="3" width="100" height="6" />
                  <rect x="140" y="5" rx="3" ry="3" width="70" height="6" />
                  <rect x="260" y="5" rx="3" ry="3" width="70" height="6" />
                  <rect x="0" y="20" rx="3" ry="3" width="85" height="6" />
                  <rect x="0" y="35" rx="3" ry="3" width="80" height="6" />
                </content-loader>
              </div>
            </div>
            <div class="jobDescription  mb-12" v-if="course && !loading">
              <div class="row subDetails">
                <div class="col-md-4"> Course duration: <span>{{ getCourseDurationText(course.course_duration) }}</span></div>
                <div class="col-md-4"> Fee: <span v-if="course.free">Free</span><span v-else><v-icon class="mdi" size="14px">{{ $icons.currencyInr }}</v-icon>{{course.course_fee}}</span></div>
                <div class="col-md-4"> Class mode: <span>{{course.class_mode }}</span></div>
                <div class="col-md-4">City: <span> {{course.city_name}}</span></div>
                <!-- <div class="col-md-4">Mobile: <span> {{course.mobile}}</span></div>
                <div class="col-md-4">Email: <span> {{ course.email }}</span></div> -->
                <div class="col-md-4">Certified Course: <span> {{ course.certified_course ? 'Yes' : 'No' }}</span></div>
                <div class="col-md-4">Placement guaranteed: <span> {{ course.placement_guarantee ? 'Yes' : 'No' }}</span></div>
              </div>
            <div v-if="course.description">
                <hr />
                <div class="description" v-html="course.description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
  </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
import { ContentLoader } from 'vue-content-loader';
import '../assets/hire.css';
import validation from '../libraries/validFormat'; 
export default {
    name:'TrainingCourses',
    components: {
      ContentLoader
    },
    metaInfo: (vue) => {
      const seo_title = vue.seo_title;
      return {
        title: seo_title ? seo_title : '',
      };
    },
    data: function(){
      return{
        seo_title: '',
        course: null,
        action: new Actions(),
        isLogin: false,
        req: {
          enquiry: '',
          course_id: [],
          interested: 1,
          institute_id: null,
          user_id: null
        },
        interested: 0,
        valid_err: 0,
        loading:true,
        validate: new validation(),
        courseDurationOptions: [
        { text: "1 week", value: 7 },
        { text: "2 weeks", value: 14 },
        { text: "3 weeks", value: 21 },
        { text: "1 month", value: 30 },
        { text: "2 months", value: 60 },
        { text: "3 months", value: 90 },
        { text: "4 months", value: 120 },
        { text: "5 months", value: 150 },
        { text: "6 months", value: 180 },
        { text: "7 months", value: 210 },
        { text: "8 months", value: 240 },
        { text: "9 months", value: 270 },
        { text: "10 months", value: 300 },
        { text: "11 months", value: 330 },
        { text: "12 months", value: 360 },
        { text: "1 year", value: 365 },
        { text: "2 years", value: 730 },
        { text: "3 years", value: 1095 },
        { text: "4 years", value: 1460 },
        { text: "5 years", value: 1825 },
      ],
      }
    },
    beforeMount(){
      console.log(this.$cookies.get("hire-seeker"));
      // let course_id = this.$route.params.id;
      this.isLogin = (this.$cookies.get("hire-seeker")&&this.$cookies.get("hire-seeker").token) ? true:false;
      if(this.isLogin){
        this.req.user_id = this.$cookies.get("hire-seeker").id;
      }
      this.getCourse();
    },
    methods: {
      showInterest: function(e){
        e.preventDefault();
        if(this.isLogin){
          let token = this.$cookies.get("hire-seeker").token;
          console.log(this.req);
          this.action.submitLead(this.req, token).then(res => {
          if(res.status){
            this.showMsgModal("Submitted interest");
            this.getCourse();
          }
          }).catch(err =>{
            console.log(err);
          })
        } else{
          this.redirect_from = this.$route.path;
          let url = '/jobseeker/register?src='+this.course.institute_name+'&redirect_from='+this.redirect_from;
          window.open(url, "_blank").focus();
        }
      },
      showMsgModal(msg = null) {
      this.boxOne = '';
      this.$bvModal.msgBoxOk(msg, {
          buttonSize: 'sm',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
      });
    },
      getCourse: function(){
        let param_split = this.$route.params.id.split('-')
        let course_id = param_split[param_split.length - 1];
        this.action.getRegisteredCourses({course_id, user_id: this.req.user_id}).then(res => {
          if(res.status){
            this.loading = false;
            this.course = res.courses[0];
            this.seo_title = this.course.course_name + ' course details | Hireclap.com';
            this.req.institute_id = this.course.institute_id;
            this.req.course_id = [course_id];
          }
        }).catch(err =>{
            this.loading = false;
            console.log(err);
        })
      },
      getInstiCourses: function(courses){
        return courses.map(course => course.course_name).join(', ');
      },
      getClassMode(mode){
        switch(mode) {
            case 0: return 'Classroom'; 
            case 1: return 'Online'; 
            case 2: return 'Both'; 
            default: break;
        }
      },
      getCourseDurationText(value) {
        const durationOption = this.courseDurationOptions.find(option => option.value === value);
        return durationOption ? durationOption.text : 'Unknown';
      },
    }
}
</script>
<style>
  .description img{
    max-width: 800px;
  }
  @media only screen and (max-width: 640px) {
   .description img{
      max-width: 300px;
    }
 }
</style>
<style scope>
 #course_options{
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
 }
 h1 {
    font-size: 22px;
    font-weight: 700;
  }
  .v-text-field__details{display: none !important;}
  .v-input__control fieldset {
    background-color: #fff; /* Your desired background color */
  }
  .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 640px) {
    h1{
      font-size: 15px;
    }
 }
</style>